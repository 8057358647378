import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Input, Spin } from 'antd';
import axios from "axios";
import { Switch } from 'antd';
import {USER_SERVER} from '../../../config'
import {calculateTimezoneOffsetInSeconds} from '../../utils/utilfunc';

function LandingDev(props) {
    const [Id, setId] = useState(props.init_id);
    const [AnswerMp3, setAnswerMp3] = useState(null);
    const [LenUserlist, setLenUserlist] = useState(0);
    const [CntRecent, setCntRecent] = useState(0);
    const [CntAnswer, setCntAnswer] = useState(0);
    const [RedisMem, setRedisMem] = useState('');
    const [NumCleanupCandidate, setNumCleanupCandidate] = useState(0);
    const [RemainTimeSchedule, setRemainTimeSchedule] = useState(0);
    const [TTS, setTTS] = useState(false);
    const [audioPlayer, setAudioPlayer] = useState(new Audio());
    const [Langcode2, setLangcode2] = useState('en');
    const [WaitAnswer, setWaitAnswer] = useState(false);
    const [API, setAPI] = useState('');
    const [APIParam, setAPIParam] = useState('');

    useEffect(()=>{
        get_system_info();
    }, []);

    useEffect(() => {
        let val;
        val = localStorage.getItem('login_id'); if (val) {setId(val);}        
        val = localStorage.getItem('api'); if (val) {setAPI(val);} 
        val = localStorage.getItem('api_param'); if (val) {setAPIParam(val);} 
    }, []);


    //////////////////////////////////////////////////////////////
    async function onClickGeneralAPITest() {
        localStorage.setItem('api', API);
        localStorage.setItem('api_param', APIParam);

        let param;
        try {
            param = JSON.parse(APIParam);
        } catch {
            alert('invalid json?');
        }

        const res = await axios.post(`${USER_SERVER}/${API}`, param);
        console.log(`${API}--------->`, res);
    }

    async function onClickGeneralAPITestMultiple() {
        await Promise.all([onClickGeneralAPITest(), onClickGeneralAPITest(), onClickGeneralAPITest(), onClickGeneralAPITest()]);
    }

    async function onClickUpdateStatus() {
        get_system_info();    
    }

    //////////////////////////////////////////////////////////////

    async function get_system_info() {
        const res = await axios.get(`${USER_SERVER}/get_system_info`);
        console.log('after get_system_info', res.data.data);
        if (res.data?.success) {
            setLenUserlist(res.data.data.len_userlist);
            setCntRecent(res.data.data.cnt_recent);
            setCntAnswer(res.data.data.cnt_answer);
            setRedisMem(res.data.data.redis_meminfo.used_memory);
            setNumCleanupCandidate(res.data.data.num_cleanup_candidate);
            setRemainTimeSchedule(parseInt(res.data.data.remain_time_schedule));
        } else {
            alert('Error:'+res.data.data);
        }
    }


    ////////////////////////////////////////////////////////////////
    function onChangeTTS() {
        setTTS(!TTS);        
    }

    const playAudio = () => {
        const audioBlob = new Blob([AnswerMp3], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);
        audioPlayer.src = audioUrl;
        audioPlayer.play();
      };


    return (
        <div>
            <h3>TESTING</h3> 
            Length of Userlist : {LenUserlist}  <br/>
            2분내에 사용한 User수 : {CntRecent}  <br/>
            지금 get_answer를 하고있는 user수 : {CntAnswer}  <br/>
            redis memory usage : {RedisMem}  <br/>
            다음 schedule 까지 남은 초 : {RemainTimeSchedule}  <br/>
            cleanup candidate 수: {NumCleanupCandidate}  <br/>
            <Button onClick={onClickUpdateStatus}>Update</Button>

            TTS: <Switch checked={TTS} onChange={onChangeTTS} checkedChildren='TTS'/>
            <button onClick={playAudio}>Play Audio</button>

            <Row style={{margin:'20px'}}>
                General API Test:
                <Input value={API} onChange={(e)=>setAPI(e.currentTarget.value)}/>
                {" param (Ex: {\"id\" : \"1234\"}): "}
                <Input value={APIParam} onChange={(e)=>setAPIParam(e.currentTarget.value)}/>
                {
                    WaitAnswer? <Spin/> :
                                <div>
                                    <Button type='primary' style={{margin:10}} onClick={onClickGeneralAPITest}>
                                        Send
                                    </Button>
                                    <Button type='primary' style={{margin:10}} onClick={onClickGeneralAPITestMultiple}>
                                        Send Multiple
                                    </Button>
                                </div>
}
            </Row>

        </div>
    )
}

export default LandingDev
