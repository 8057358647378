import React from 'react';
import {withRouter} from "react-router-dom";

function CarPicture({HwState, change_Hw_state}) {
    console.log("HwState", HwState);
    return (
        <div
            className={"car-picture"}
            style={{
                width: "100%",
                height: "100%",
                maxHeight: "500px",
                borderRadius: "10px",
                backgroundColor: "#848484",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 20px 10px",
                position: "absolute",
                bottom: "100px",
            }}
        >
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                }}
            >
                <img src={"/car/car.png"} alt={"/car/car.png"}/>
                {
                    HwState.steering_wheel === "true" ?
                        <img src={"/car/steering_wheel.png"} alt={"/car/steering_wheel.png"}/> :
                        <div/>
                }
                {
                    HwState.tail_gate === "true" ?
                        <img src={"/car/tail_gate.png"} alt={"/car/tail_gate.png"}/> :
                        <div/>
                }
                {
                    HwState.window0 === "true" ?
                        <img src={"/car/window0.png"} alt={"/car/window0.png"}/> :
                        <div/>
                }
                {
                    HwState.window1 === "true" ?
                        <img src={"/car/window1.png"} alt={"/car/window1.png"}/> :
                        <div/>
                }
                {
                    HwState.window2 === "true" ?
                        <img src={"/car/window2.png"} alt={"/car/window2.png"}/> :
                        <div/>
                }
                {
                    HwState.window3 === "true" ?
                        <img src={"/car/window3.png"} alt={"/car/window3.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_heater0 === "true" ?
                        <img src={"/car/seat_heater0.png"} alt={"/car/seat_heater0.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_heater1 === "true" ?
                        <img src={"/car/seat_heater1.png"} alt={"/car/seat_heater1.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_heater2 === "true" ?
                        <img src={"/car/seat_heater2.png"} alt={"/car/seat_heater2.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_heater3 === "true" ?
                        <img src={"/car/seat_heater3.png"} alt={"/car/seat_heater3.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_cooler0 === "true" ?
                        <img src={"/car/seat_cooler0.png"} alt={"/car/seat_cooler0.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_cooler1 === "true" ?
                        <img src={"/car/seat_cooler1.png"} alt={"/car/seat_cooler1.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_cooler2 === "true" ?
                        <img src={"/car/seat_cooler2.png"} alt={"/car/seat_cooler2.png"}/> :
                        <div/>
                }
                {
                    HwState.seat_cooler3 === "true" ?
                        <img src={"/car/seat_cooler3.png"} alt={"/car/seat_cooler3.png"}/> :
                        <div/>
                }
                {
                    HwState.clova === "true" ?
                        <img src={"/car/clova.png"} alt={"/car/clova.png"} style={{
                            top: 0,
                            right: 0,
                            objectFit: "contain",
                            objectPosition: "top right",
                            width: "30%",
                        }}/> :
                        <div/>
                }
            </div>
            <div
                style={{
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    margin: "10px 0 0 0",
                    padding: "10px",
                }}
            >
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: 'inline-block',
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#838691",
                                borderRadius: "200px",
                                margin: "0 10px 0 0",
                            }}
                        />
                        현재위치
                    </div>
                    <input
                        type={"text"}
                        style={{
                            width: "60%",
                        }}
                        value={HwState.curr_position}
                        placeholder={"출발지"}
                        onChange={(event) => {
                            change_Hw_state({"curr_position": event.target.value});
                        }}
                    />
                </div>
                <div
                    style={{
                        width: "5px",
                        display: "flex",
                        justifyContent: "right",
                    }}
                >
                    <div
                        style={{
                            width: "0px",
                            height: '15px',
                            borderRight: "1px solid #838691",
                        }}
                    />
                </div>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <div
                            style={{
                                display: 'inline-block',
                                width: "10px",
                                height: "10px",
                                backgroundColor: "#44227a",
                                borderRadius: "200px",
                                margin: "0 10px 0 0",
                            }}
                        />
                        목적지
                    </div>
                    <input
                        className={"position-input"}
                        type={"text"}
                        style={{
                            width: "60%",
                        }}
                        placeholder={"목적지"}
                        value={HwState.target_position}
                        onChange={(event) => {
                            change_Hw_state({"target_position": event.target.value});
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default withRouter(CarPicture)
