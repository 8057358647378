import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { auth } from "../_actions/user_actions";

// adminRoute=true --> admin user
export default function _auth(ComposedClass, reload, adminRoute = null) {

    function AuthenticationCheck(props) {
        let user = useSelector((state) => state.user);
        const dispatch = useDispatch();

        /**
         * Show Un Authorize Popup
         */
        const showAuthMessage = (path) => {
            Swal.fire({
                title: ('Unauthorized'),
                text: ("We're sorry! You can't access this page."),
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Okay'
            }).then((result) => {
                if (result.isConfirmed) {
                    props.history.push(path);
                }
            })
        }

        useEffect(() => {
            dispatch(auth()).then(async (response) => {
                if (await !response.payload.isAuth) {
                    if (reload) {
                        showAuthMessage("/login");
                    }
                } else {
                    if (adminRoute && !response.payload.isAdmin) {
                        props.history.push("/");
                    } else {
                        if (reload === false) {
                            props.history.push("/");
                        }
                    }
                }
            });
        }, [dispatch, props.history]);

        return <ComposedClass {...props} user={user} />;
    }
    return withRouter(AuthenticationCheck);
}
