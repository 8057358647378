import React, { useState } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import { Modal } from "antd";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { getCookie } from "../../../cookie/cookie";
//import { GOOGLE_OAUTH_CLIENT_ID } from "../../../config";
import { loginUser, loginGoogleUser } from "../../../_actions/user_actions";
import {REACT_APP_GOOGLE_CLIENT_ID} from "../../../config";
import {calculateTimezoneOffsetInSeconds} from "../../utils/utilfunc"

function LoginPage(props) {

    const dispatch = useDispatch();

    const [Userinfo, setUserinfo] = useState("");
    const [Password, setPassword] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(true);

    const onUserinfoHandler = (event) => {
        setUserinfo(event.currentTarget.value);
    };

    const onPasswordHandler = (event) => {
        setPassword(event.currentTarget.value);
    };

    function respond_to_result(response) {
        const resdata = response.payload;
        console.log('resdata=', resdata);

        if (resdata.loginSuccess) {
            let message = ("Logged in successfully.");
            Swal.fire(("Success"), message, "success");
            props.history.push("/");
        } else {
            Swal.fire({ icon: "error", title: ("Oops..."), text: ('Something went wrong!!!') });
        }
    }

    // const onSubmitHandler = (event) => {
    //     event.preventDefault();
    //     const body = { userinfo: Userinfo, password: Password };
    //     dispatch(loginUser(body)).then((response) => {
    //         respond_to_result(response);
    //     });
    // };

    function onSuccessGoogle(googleUser) {
        const clientId = googleUser.clientId;
        const credential = googleUser.credential;
        const time_diff  = calculateTimezoneOffsetInSeconds();
    
        const body = {clientId, credential, time_diff};
        dispatch(loginGoogleUser(body)).then((response) => {
            respond_to_result(response);
        });
    };

    function onFailureGoogle(result) {
        console.log("fail google", result);
    }

    // function handleOk() {
    //     setIsModalVisible(false);
    // }

    // function handleCancel() {
    //     setIsModalVisible(false);
    //     props.history.push("/");
    // }

    return (
        <div>
              <GoogleOAuthProvider
                clientId={REACT_APP_GOOGLE_CLIENT_ID}
              >
                <GoogleLogin
                  onSuccess={onSuccessGoogle}
                  onFailure={onFailureGoogle}
                  ux_mode={"popup"}
                />
              </GoogleOAuthProvider>
        </div>
    );
}

export default withRouter(LoginPage);
