import { apiUrl } from '../../config';

// export function get_extension(fname) {
//     return fname.split('.').pop();
// }

export function calculateTimezoneOffsetInSeconds() {
    // 현재 로컬 시간을 가져옵니다.
    var now = new Date();

    // UTC 시간을 생성합니다.
    var nowInUtc = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()));

    // 로컬 시간과 UTC 시간의 차이를 밀리초 단위로 계산합니다.
    var differenceInMilliseconds = nowInUtc - now;

    // 차이를 초 단위로 변환합니다.
    var differenceInSeconds = parseInt(differenceInMilliseconds / 1000);

    return differenceInSeconds;
}

export function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
}
