import {sleep} from "./utilfunc"

class MyAudioRecorder {

    constructor(func_send = null) {
        this.chunks = [];
        this.sendDataToBackend = func_send;
    }

    async release() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
        delete this.stream;
    }

    async openMic() {
        try {
            console.log('before getUserMedia');
            await this.release();
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                const _stream = await navigator.mediaDevices.getUserMedia(
                    { 
                        audio: {
                            echoCancellation: false, 
                            noiseSuppression: false
                            // sampleRate : 32000                            
                        },
                        video: false 
                    });

                if (_stream) {
                    this.stream = _stream;
                }
            }
        } catch (err) {
            console.log('MyAudioRecorder:openMic:error:getUserMedia:', err.message);
        }
    }

    consult_possible_codecs() {
        const types = [
            "audio/webm",
            "audio/webm;codecs=opus",
        ];
          
        for (const type of types) {
            console.log(
                `Is ${type} supported? ${
                    MediaRecorder.isTypeSupported(type) ? "Maybe!" : "Nope :("
                }`
            );
        }
    }

    // 녹음 완료후 한번에 전송하는 경우
    async start_recording() {
        this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: 'audio/webm;codecs=opus' });
        this.mediaRecorder.ondataavailable = (_event) => {
            this.chunks.push(_event.data);
        };
        
        this.mediaRecorder.onstop = (_event) => {
            this.blobs = new Blob(this.chunks, { type: 'audio/webm' });
            if (this.blobs.size < 1000) {
                this.url = 'too_short';
            } else {
                this.url = URL.createObjectURL(this.blobs);
            }
        };
        
        this.mediaRecorder.start();
    }

    // 100msec 간격으로 전송하는 경우
    // async start_recording() {
    //     this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: 'audio/webm;codecs=opus' });
    
    //     this.mediaRecorder.ondataavailable = async (event) => {
    //         if (event.data.size > 0) {
    //             // Send this data to your backend
    //             await this.sendDataToBackend(event.data);
    //         }
    //     };
        
    //     // Start recording with a timeslice of 100 milliseconds
    //     this.mediaRecorder.start(100);
    // }

    async stop_recording() {
        await this.mediaRecorder.stop();
        await this.release();
    }

    get_recorded_blob() {
        return this.blobs;
    }

    get_blob_url() {
        return this.url;
    }
};

export default MyAudioRecorder;