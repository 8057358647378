import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router-dom";
import { Row, Col, Input, Button, Dropdown, Checkbox } from 'antd';
import axios from "axios";
import {USER_SERVER} from '../../../config'

const { TextArea } = Input;

function LandingScenario(props) {
    const [EnableTool, setEnableTool] = useState(true);
    // const [EnableLlama2, setEnableLlama2] = useState(false);
    const [Prompt, setPrompt] = useState('');
    const [AnswerOpenai, setAnswerOpenai] = useState('');
    // const [AnswerLlama2, setAnswerLlama2] = useState('');
    const [Temperature, setTemperature] = useState(0.9);
    const [Top_p, setTop_p] = useState(1);
    const [Freq_pen, setFreq_pen] = useState(0.6);
    const [Pres_pen, setPres_pen] = useState(0.6);
    const [MaxTokens, setMaxTokens] = useState(400);
    const [StopWord, setStopWord] = useState('USER:');
    const [Slot, setSlot] = useState('slot1');

    const items = [
        {label: (<div><Button onClick={e=>setSlot('slot1')}>slot1</Button> <Button onClick={e=>setSlot('slot5')}>slot5</Button></div>), key:'1'},
        {label: (<div><Button onClick={e=>setSlot('slot2')}>slot2</Button> <Button onClick={e=>setSlot('slot6')}>slot6</Button></div>), key:'2'},
        {label: (<div><Button onClick={e=>setSlot('slot3')}>slot3</Button> <Button onClick={e=>setSlot('slot7')}>slot7</Button></div>), key:'3'},
        {label: (<div><Button onClick={e=>setSlot('slot4')}>slot4</Button> <Button onClick={e=>setSlot('slot8')}>slot8</Button></div>), key:'4'},
    ];

    // Load the value from localStorage when the component mounts
    useEffect(() => {
        let val;
        //val = localStorage.getItem('prompt'); if (val) {setPrompt(val);}
        //val = localStorage.getItem('enable_tool'); if (val!==null) {val==='true'? setEnableTool(true): setEnableTool(false);}
        //val = localStorage.getItem('enable_llama2'); if (val!==null) {val==='true'? setEnableLlama2(true): setEnableLlama2(false);}
        val = localStorage.getItem('slot'); if (val) {setSlot(val);}
        val = localStorage.getItem('temperature'); if (val) {setTemperature(val);}
        val = localStorage.getItem('top_p'); if (val) {setTop_p(val);}
        val = localStorage.getItem('freq_pen'); if (val) {setFreq_pen(val);}
        val = localStorage.getItem('pres_pen'); if (val) {setPres_pen(val);}
        val = localStorage.getItem('maxtokens'); if (val) {setMaxTokens(val);}
        val = localStorage.getItem('stopword'); if (val) {setStopWord(val);}
    }, []);

    useEffect(() => {
        let val;
        localStorage.setItem('slot', Slot);
        val = localStorage.getItem('prompt'+Slot); if (val) {setPrompt(val);} else {setPrompt('');}
    }, [Slot]);

    function onChangePrompt(e) {
        setPrompt(e.target.value );
    }

    async function onClickSubmit() {
        const enable_openai=true;
        const enable_llama2=false;
        //const enable_tool = EnableTool;
        const prompt      = Prompt;
        const temperature = parseFloat(Temperature);
        const top_p       = parseFloat(Top_p);
        const freq_pen    = parseFloat(Freq_pen);
        const pres_pen    = parseFloat(Pres_pen);
        const max_tokens  = parseInt(MaxTokens);
        const stopword    = StopWord;
        const gpt_timeout = 40;

        setAnswerOpenai('');
        // setAnswerLlama2('');

        // local storage에 저장
        localStorage.setItem('prompt'+Slot, Prompt);

        const dataToSubmit = {enable_openai, enable_llama2, prompt, temperature, top_p, freq_pen, pres_pen, max_tokens, stopword, gpt_timeout};
        const res = await axios.post(`${USER_SERVER}/scenario_prompt`, dataToSubmit);

        if (res.data.success) {
            const data = res.data.data.openai;
            console.log('scenario_prompt--->', data);
            if (data?.function) {
                const msg = `name=${data.function.name},\n\n args=${data.function.arguments}`;
                setAnswerOpenai(msg);    
            } else {
                setAnswerOpenai(data.content);
            }
            
            // setAnswerLlama2(res.data.data.llama2);
        } else {
            alert(res.data.data);
        }
    }

    function onClickAdd(){
        const new_prompt = `${Prompt}\n###assistant\n${AnswerOpenai}\n###user\n`;
        ///const new_prompt = `${Prompt}${Answer}\nUSER:\nASSISTANT:\n`;
        localStorage.setItem('prompt'+Slot, new_prompt);
        setPrompt(new_prompt);
    }

    function onChangeTemperature(e){
        const val = e.target.value;
        localStorage.setItem('temperature', val);
        setTemperature(val);
    }

    function onChangeTop_p(e){
        const val = e.target.value;
        localStorage.setItem('top_p', val);
        setTop_p(val);
    }

    function onChangeFreq_pen(e){
        const val = e.target.value;
        localStorage.setItem('freq_pen', val);
        setFreq_pen(val);
    }

    function onChangePres_pen(e){
        const val = e.target.value;
        localStorage.setItem('pres_pen', val);
        setPres_pen(val);
    }

    function onChangeMaxTokens(e){
        const val = e.target.value;
        localStorage.setItem('maxtokens', val);
        setMaxTokens(val);
    }

    function onChangeStopWord(e){
        const val = e.target.value;
        localStorage.setItem('stopword', val);
        setStopWord(val);
    }

    // function onChangeEnableTool(e){
    //     const val = e.target.checked;
    //     localStorage.setItem('enable_tool', val);
    //     console.log('change:', EnableTool, val)
    //     setEnableTool(val);
    // }

    // function onChangeEnableLlama2(e){
    //     const val = e.target.checked;
    //     localStorage.setItem('enable_llama2', val);
    //     setEnableLlama2(val);
    // }

    // focus를 잃을 때 발생
    function onBlur() {
        localStorage.setItem('prompt'+Slot, Prompt);
    }

    function IsEmptyAnswer() {
        const res = (AnswerOpenai==='');
        return res
    }

    return (
        <Row>
            <Col span={18}>
                <div style ={{marginBottom:'20px'}}>
                    <Row justify="center" style={{paddingTop:'10px'}}>
                        Prompt
                    </Row>
                    <Row justify="center">
                        <Col span={22}>
                            <TextArea rows={16} value={Prompt} onChange={onChangePrompt} onBlur={onBlur}/>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row justify="center" style={{paddingTop:'10px'}}>
                        OpenAI Answer
                    </Row>
                    <Row justify="center" style={{paddingTop:'10px'}}>
                        <Col span={22}>
                            {/* <TextArea rows={4} value={AnswerOpenai}/> */}
                            {AnswerOpenai}
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col span={4} style ={{margin:'10px'}}>
                {/* <Row>
                    <Col>
                        <Checkbox checked={EnableTool} onChange={onChangeEnableTool}>use Function Call</Checkbox>
                    </Col>
                </Row> */}
                Temperature(0~1)<Input value={Temperature} onChange={onChangeTemperature}/>
                Top_p(0~1)<Input value={Top_p} onChange={onChangeTop_p}/>
                Freq_pen(0~2)<Input value={Freq_pen} onChange={onChangeFreq_pen}/>
                Pres_pen(0~2)<Input value={Pres_pen} onChange={onChangePres_pen}/>
                MaxTokens(1~4000)<Input value={MaxTokens} onChange={onChangeMaxTokens}/>
                StopWord<Input value={StopWord} onChange={onChangeStopWord}/>

                <Row  justify="center" style ={{marginTop:'10px'}}>
                    <Col>
                        <Button type='primary' onClick={onClickSubmit} style={{margin:5}}>Submit</Button>
                    </Col>
                    <Col>
                        <Button type='primary' disabled={IsEmptyAnswer()} onClick={onClickAdd} style={{margin:5}}>Add To Prompt</Button>
                    </Col>
                </Row>
                <Row  justify="center" style={{paddingTop:20}}>
                    사용할 저장소를 선택하세요:
                </Row>
                <Row  justify="center" style={{paddingTop:0}}>
                    <Dropdown menu={{ items }}>
                        <h3><a>{Slot}</a></h3>
                    </Dropdown>
                </Row>

            </Col>
        </Row>
    )
}

export default withRouter(LandingScenario)
