/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, Fragment } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CaseLoged from "./CaseLoged";
import CaseNotLoged from "./CaseNotLoged";

function RightMenu(props) {

    const { history } = props;
    const user = useSelector((state) => state.user);

    const [isSubOpen, setIsSubOpen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isNotifyOpen, setIsNotifyOpen] = useState(false);

    useEffect(() => {

        isDrawerOpen === true && setIsDrawerOpen(false);
        isSubOpen === true && setIsSubOpen(false);
        isSubMenuOpen === true && setIsSubMenuOpen(false);    
        isNotifyOpen === true && setIsNotifyOpen(false);
        
    }, [history.location.pathname]);

    const subMenuShow = () => {

        setIsSubMenuOpen(false);
        setIsNotifyOpen(false);
        setIsSubOpen(!isSubOpen);
    }

    const subMenuOpen = () => {

        setIsSubOpen(false);
        setIsNotifyOpen(false);
        setIsSubMenuOpen(!isSubMenuOpen);
    }

    const toggleDrawer = () => {

        setIsSubOpen(false);
        setIsSubMenuOpen(false);
        setIsNotifyOpen(false);
        setIsDrawerOpen(!isDrawerOpen);
    };

    const toggleNotificationDrawer = () => {

        setIsSubOpen(false);
        setIsSubMenuOpen(false);
        setIsDrawerOpen(false);
        setIsNotifyOpen(!isNotifyOpen);
    };

    const location = useLocation();

    return (
        <Fragment>
            {
                (location.pathname==='/delete')? '' :
                (user.userData && user.userData.isAuth) ?
                    <CaseLoged 
                        user={user} history={history}
                        isDrawerOpen={isDrawerOpen} isSubOpen={isSubOpen} isSubMenuOpen={isSubMenuOpen} isNotifyOpen={isNotifyOpen}
                        subMenuShow={subMenuShow} toggleDrawer={toggleDrawer} toggleNotificationDrawer={toggleNotificationDrawer} subMenuOpen={subMenuOpen} /> :
                    <CaseNotLoged
                        history={history}
                        isDrawerOpen={isDrawerOpen} isSubOpen={isSubOpen} isSubMenuOpen={isSubMenuOpen}
                        subMenuShow={subMenuShow} toggleDrawer={toggleDrawer} subMenuOpen={subMenuOpen} />
            }
        </Fragment>
    );
};

export default withRouter(RightMenu);
