import React, {useState, useEffect} from 'react';
import {withRouter} from "react-router-dom";
import {Row, Col, Input} from 'antd';
import {useSelector} from "react-redux";
import Chat from './Chat';
import CarPicture from './CarPicture';

function LandingPage(props) {
    const initialList = {
        window0: 'false',
        window1: 'false',
        window2: 'false',
        window3: 'false',

        seat_heater0: 'false',
        seat_heater1: 'false',
        seat_heater2: 'false',
        seat_heater3: 'false',

        seat_cooler0: 'false',
        seat_cooler1: 'false',
        seat_cooler2: 'false',
        seat_cooler3: 'false',

        steering_wheel: 'false',
        tail_gate: 'false',

        // latitude:               '41.6819',
        // longitude:              '-85.9767',
        curr_position: '서울 삼성동 코엑스',
        target_position: '평택시 동삭로',

        // 클로바
        clova: 'false',

        debug_info: 'blabla...'
    };

    console.log('Hw start ======================');
    let user = useSelector((state) => state.user);
    const UserInfo = user.userData;     // UserInfo={token, user_id}
    //console.log('UserInfo =', UserInfo);

    const [HwState, setHwState] = useState(initialList);

    function change_Hw_state(changes) {
        console.log('change_Hw_state', changes);
        setHwState(prev => ({...prev, ...changes}));
    }

    return (
        <div>
            <Row
                style={{
                    padding: "20px",
                    width: "95%",
                    height: "80vh",
                }}
            >
                <Col span={19}>
                    <Chat HwState={HwState} change_Hw_state={change_Hw_state} UserInfo={UserInfo}/>
                </Col>
                <Col span={1}/>
                <Col span={4} style={{position: "relative"}}>
                    <CarPicture HwState={HwState} change_Hw_state={change_Hw_state}/>
                </Col>
            </Row>
        </div>
    )
}

export default withRouter(LandingPage)
